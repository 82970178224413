// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import map from 'lodash/map'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import Link from '../components/link'
import '../components/link/style.less'

import flattenGatsbyNode from '../methods/flatten-gatsby-node'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React
const pageSchema = {
  title: 'About',
  slug: '/about',
  abstract: 'About',
  breadcrumbs: [
    { title: 'Homepage', slug: '' },
    { title: 'About', slug: '/about' },
  ],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    _about: allFile(
      filter: {
        fields: {
          pathAuto: { regex: "/^(?:(/about/))/" }
          autoNodeType: { eq: "resourceNode" }
          draftAuto: { eq: false }
        }
      }
      sort: { order: ASC, fields: [childResources___title] }
    ) {
      nodes {
        fields {
          pathAuto
        }
        childResources {
          title
          shortAbstract
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = (props) => {
  const {
    _about: { nodes: aboutNodes },
  } = props.data
  const about = map(aboutNodes, flattenGatsbyNode)

  return (
    <StandardPageWrapper className="" pageSchema={pageSchema} {...props}>
      <h1 className="flow">About</h1>
      <p>
        The teachings of Sri Aurobindo and Mother help and guide spiritual
        seekers all over the world. Their greatness draws seekers of Truth to
        follow the path of yoga which they walked and made into a way for others
        to follow to attain greater consciousness. A sincere practitioner of Sri
        Aurobindo’s Yoga will find that, no matter how long it takes, it is a
        sure way to freedom and enlightenment. This website offers a collection
        of talks on the yoga and work and lives of Sri Aurobindo and Mother, and
        books containing compilations of their writings and sayings. The talks
        are audios and videos of public lectures, and classes which have been
        broadcast on Auroville Radio over the past seven years. The books,
        published in Auroville, present teachings on the principle subjects
        taught by Sri Aurobindo and Mother.
      </p>
      <br />
      {map(about, ({ pathAuto, title, shortAbstract }) => (
        <Link to={pathAuto}>
          <h2>{title}</h2>
          <p className="tj">{shortAbstract}</p>
          <p className="tj">See more ⟶</p>
          <br />
        </Link>
      ))}
    </StandardPageWrapper>
  )
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
